export default {
  title: 'One-stop Data Service',
  pass: 'Pass Rate',
  finish: 'Completion Rate',
  count: 'Total People',
  customizeT1: 'Get Demand',
  customizeD1: 'Listen to customer needs, exchange ideas, communicate and negotiate.',
  customizeT2: 'Make Plan',
  customizeD2: 'Specify an exclusive plan for the special needs of customers.',
  customizeT3: 'Implement Plan',
  customizeD3: 'Supervise the implementation of the plan, and coordinate all parties to carry out the project.',
  customizeT4: 'Process Control',
  customizeD4: 'Follow up the progress of the project in real time and monitor the whole process to avoid mistakes.',
  customizeT5: 'Data delivery',
  customizeD5: 'Review and check data quality, and deliver data to complete acceptance.',
  clientTitle: 'Client ',
  taggingTitle: 'Tagging Clerk',
  items: [
    {
      name: 'Project leader of a Research Institute',
      details: 'Fanhan Tech is professional, which provides a lot of suggestions on collection and labeling for data, and they can always complete the data with quality and quantity guaranteed.'
    },
    {
      name: 'A senior tagging clerk',
      details: 'I have done some projects of several companies. Compared with other platforms, the task interface of Fanhan Tech platform is easy to operate because they have some keyboard shortcuts, which promote efficiency.'
    },
    {
      name: 'Project leader of an automated driving company',
      details: 'The cooperation with Fanhan Tech has always been very pleasant. The response for demand is timely, and they can find ways to help us solve problems, especially in terms of efficiency, they can deliver 200000 labeled data of pics within a day, so they are reliable.'
    },
    {
      name: 'A student in school',
      details: 'I use my spare time to do Cantonese tasks on the Fanhan Tech platform. The platform is very reassuring, and once the task is reviewed, the points will be paid immediately.'
    },
    {
      name: 'Project leader of a voice information service company',
      details: 'Recently, we have 400 hours of voice data need to be labeled.We selected Fanhan Tech after comparing several data labeling platforms. Their communication for demand is frequent and labeling results are responsible. The price is not expensive, which is more secure than some online small teams.'
    },
    {
      name: 'Leader of a labeling team',
      details: 'It has been nearly 2 years since we started the collection and labeling project on the platform of Fanhan Tech. The platform is very convenient, and for the difficult projects, they will arrange special personnel to train us. Wish Fanhan Tech will become better and better.'
    }
  ]
}
