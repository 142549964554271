export default {
  title: '泛函沃客APP',
  download: '下载',
  banner: '随时随地打开手机轻松完成任务即可赚钱。快快行动起来下载泛函沃客app开始赚钱啦！',
  feature1: '高效',
  feature2: '方便',
  feature3: '快捷',
  feature4: '安全',
  links: '扫码下载：',
  howdoTitle: '如何使用泛函沃客APP进行任务',
  step1: '实名认证',
  step2: '领取任务',
  step2_1: '在推荐任务列表中选择任务',
  step2_2: '在全部任务列表中选择任务',
  step3: '查看待提交任务',
  step4: '查看审核进度',
  step5: '提现',
  step6: '签到入口'
}
