export default {
  title: '一站式数据服务',
  pass: '标注合格',
  finish: '项目完成',
  count: '标注人数',
  customizeT1: '需求洽谈',
  customizeD1: '倾听客户需求，交流想法沟通协商。',
  customizeT2: '定制方案',
  customizeD2: '针对客户特殊需求，制定专属计划方案。',
  customizeT3: '方案实施',
  customizeD3: '督促方案落地实施，各方协调开展项目。',
  customizeT4: '实时监控',
  customizeD4: '督促方案落地实施，各方协调开展项目。',
  customizeT5: '交付数据',
  customizeD5: '审核检查数据质量，交付数据完成验收。',
  clientTitle: '客户',
  taggingTitle: '标注员',
  items: [
    {
      name: '某研究院项目负责人',
      details: '泛函的服务还是很专业的，帮助我们提供很多专业数据采集标注方面的建议，在数据交付方面一直都能保质保量的完成。'
    },
    {
      name: '某资深标注员',
      details: '做过一些标注公司的项目，相比较而言泛函平台的任务界面操作比较方便，功能键很多，对标注员很友好。'
    },
    {
      name: '某自动化驾驶公司项目负责人',
      details: '跟泛函科技的合作一直都非常愉快，需求响应很及时，有问题也能想办法帮客户解决，特别是在效率提升方面可以做到1天20万张标注数据的交付，是值得信赖的合作伙伴。'
    },
    {
      name: '某在校生',
      details: '我利用业余的时间在泛函平台上做粤语任务，平台还是很值得放心的，每次任务过审，积分就立刻到账了。'
    },
    {
      name: '某语音信息服务公司项目负责人',
      details: '最近手上有400小时的语音类数据需要标注，因公司内部雇人标注，人员管理起来麻烦，而且项目比较紧急，所以在对比了几家数据标注平台后，最终选择了泛函科技。需求沟通很频繁，标注工具很专业，标注结果也很负责任。总体价格不贵，相对于一些网上比较多的标注小团体来说更有保障。'
    },
    {
      name: '某标注团队负责人',
      details: '在泛函平台做采集和标注项目快有2年了，平台操作很方便，对于难度大的项目还会安排专门的人员对我们进行培训，希望泛函平台能越来越好。'
    }
  ]
}
