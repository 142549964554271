export default {
  ideaDetail: "It has always been the business philosophy of Fanhan Tech, that \"We don't just offer data, we make a difference\". Featured by accurate data and high-quality services, Fanhan Tech is aimed at promoting the development of AI with our partners. It is hoped that Fanhan Tech would contribute meager strength to the future of human.",
  idea1: 'Fanhan Tech responds to the needs from clients quickly and seeks effective communication.',
  idea2: 'Fanhan Tech offers the highest quality data, platforms and services for our clients.',
  idea3: 'Fanhan Tech provides customized data services based on needs from our clients.',
  idea4: 'Fanhan Tech offers absolutely authentic data,and establishes sincere relationships with our clients.',
  teamN1: 'Young Team',
  teamN2: 'Technical Development',
  teamN3: 'Project Management',
  team1D1: 'Fanhan Tech is affiliated to Suzhou Fanhan Tech Information Technology Company. The core team is a group of ',
  team1D2: 'young and energetic',
  team1D3: ' youths, including a technologically advanced ',
  team1D4: 'R&D team',
  team1D5: ' and an experienced project ',
  team1D6: 'management team',
  team1D7: '. These outstanding talents from different fields such as ',
  team1D8: 'voice, picture, video',
  team1D9: ' are committed to promoting The future development of artificial intelligence.',
  team2D1: 'At present, Fanhan Tech has ',
  team2D2: '15 technical developers (including 2 doctors and 13 masters)',
  team2D3: ' who are responsible for the development of auxiliary tools for data collection and data processing such as ',
  team2D4: 'voice, picture, text, and video',
  team2D5: ', as well as the difficulty evaluation of R&D tasks, data pricing, and database Algorithms such as quality evaluation can provide users with efficient technical support services.',
  team3D1: 'Fanhan Tech also has a professional project management team with rich experience in project management, quality control and cost optimization, which can meet the individual needs of customers for mass data collection, processing and labeling, and can achieve ',
  team3D2: 'high efficiency, high cost performance and high-quality data',
  team3D3: ' delivery.',
  honorN1: 'Certification',
  honorN2: 'Software Works',
  honorN3: 'Quality Certification',
  honorN4: 'Other Honors',
  mapf1: 'Suzhou',
  mapf2: 'Building E, Suzhou National University Science and Technology Park of Nanotechnology, 388 Ruoshui Road, Suzhou Industrial Park, Suzhou 215128, PRC.',
  maps1: 'Beijing',
  maps2: '3rd Floor, Building 2, Chuangye Street, Zhongguan Village, Haidian District, Beijing City',
  mapt1: 'Toronto',
  news: 'News',
  news1: 'Functional Technology won the third prize in the entrepreneurial group of the "China Chuangyi" entrepreneurial innovation competition',
  news2: 'Fengxian Technology is selected as the list of planned nursery projects for listing',
  news3: 'Fanhan Tech is awarded the title of "Star of Cooperation" in Suzhou National University Science and Technology Park of Nanotechnology in 2018',
  news4: 'Fanhan Tech was awarded the title of "Star of Innovation" of the year in the third Equity Roadshow of Southern Jiangsu',
  news5: 'Functional Technology won the second prize of the entrepreneurial innovation competition',
  joinTxt: 'We hope to promote the construction of artificial intelligence ecological chain with high-quality data and services with our partners. You are welcome to join us to make truly beneficial changes for the development of society in the future.',
  joinBtn: 'Apply',
  honor: 'Honor',
  partner: 'Partners',
  join: 'Join Us'
}
