export const newsList = [
  {
    id: 11,
    title: '全省第一！园区7家单位入围，【泛函科技】榜上有名',
    time: '2022.04.08'
  },
  {
    id: 10,
    title: '喜报！泛函科技在首届全球科大人创新创业大赛荣获佳绩!',
    time: '2021.09.28'
  },
  {
    id: 9,
    title: '泛函标注平台|项目发布模块即将上线',
    time: '2021.03.16'
  },
  {
    id: 8,
    title: '泛函科技通过CMMI三级认证',
    time: '2021.02.05'
  },
  {
    id: 7,
    title: '泛函科技荣获“中国·苏州”大学生创业创新大赛暨“青年创业之星”大赛二等奖',
    time: '2020.11.20'
  },
  {
    id: 6,
    title: '等你一起 · 中科大秋季校园招聘会',
    time: '2020.10.13'
  },
  {
    id: 5,
    title: '泛函标注平台项目模板更新',
    time: '2020.09.18'
  },
  {
    id: 4,
    title: '泛函科技荣获“中国创翼”创业创新大赛创业组三等奖',
    time: '2020.06.28'
  },
  {
    id: 3,
    title: '泛函科技入选上市苗圃工程拟认定名单！',
    time: '2020.05.10'
  },
  {
    id: 2,
    title: '泛函科技荣获2018苏州纳米技术国家大学科技园“合作之星”称号',
    time: '2018.05.20'
  },
  {
    id: 1,
    title: '泛函科技荣获第三届苏南股权路演年度“创新之星”称号',
    time: '2017.05.20'
  }
]
