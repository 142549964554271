export default {
  platform: '泛函标注平台',
  info1: '任务发布新渠道，成本可控，为您提供高质量数据服务',
  info11: '成本可控',
  info12: '自行把关',
  info2: '网络兼职新方向，无需押金，一元轻松提现，轻松赚取零花钱',
  info21: '无需押金',
  info22: '一元提现',
  in: '立即入驻',
  how: '如何进行',
  img: '图片采集',
  voice: '语音采集',
  text: '文字采集',
  video: '视频采集',
  step1: '第一步',
  step2: '第二步',
  step3: '第三步',
  all3: '完成任务并提交<br>获得相应泛函币',
  img1: '获取任务<br>请拍摄街道上的行人',
  img2: '用户拍摄相应的图片',
  voice1: '大声朗读任务中的句子<br/>例如：“你好，泛函”',
  voice2: '用户对着录音设备朗读<br>“你好，泛函”',
  text1: '模拟场景编写一问一答对话',
  text2: '用户编写对话并核对逻辑',
  video1: '根据任务内容采集视频片段',
  video2: '用户采集相应的视频片段'
}
