<template>
  <div class="news-item" @click="pathTo('/news-info', news.id)">
    <img :src="imgurl">
    <div class="title">{{news.title}}</div>
    <div class="info">
      <span class="tag">{{tag}}</span>
      <span class="time">{{news.time}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NameItem',
  props: {
    imgurl: String,
    tag: String,
    news: Object
  },
  methods: {
    pathTo (path, id) {
      const { origin, pathname } = window.location
      window.location.href = origin + path + '.html#' + id
      if (pathname === '/news-info.html') {
        window.location.reload()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.news-item {
  cursor: pointer;
  margin: 1.5rem 0;
  img {
    width: 100%;
  }
  .title {
    font-size: 14px;
    color: #333;
    margin: 15px 0;
  }
  .info {
    font-size: 12px;
  }
  .tag {
    color: #3780FF;
    margin-right: 5px;
  }
  .time {
    color: #999;
  }
}
</style>
