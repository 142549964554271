export default {
  banT1: '欧美地区',
  banT2: '亚太-中东地区',
  banT3: '非洲地区',
  language: '语种',
  distributed: '分布',
  partPar: '泛函科技常年为Google、Facebook、百度、三星等全球知名企业提供数据服务，建立了长期稳定的合作关系，为AI未来发展共同努力。',
  joinPar: '我们希望以高质量的数据与服务和业界合作伙伴一起推动建设人工智能生态链，欢迎你加入我们，为社会未来的发展做出真正有益的改变一起创造人工智能的未来。',
  joinApply: '申请加入',
  details: '详细情况',
  ban1: [
    {
      language: '英语',
      distribution: '美国、英国、新西兰、澳大利亚、爱尔兰、印度、新加坡、加拿大、墨西哥'
    },
    {
      language: '法语',
      distribution: '法国、加拿大、瑞士（少量）、比利时'
    },
    {
      language: '德语',
      distribution: '德国、奥地利、瑞士、比利时（少量）'
    },
    {
      language: '意大利语',
      distribution: '意大利'
    },
    {
      language: '西班牙语',
      distribution: '西班牙、墨西哥、阿根廷'
    },
    {
      language: '葡萄牙语',
      distribution: '葡萄牙、巴西'
    },
    {
      language: '土耳其语',
      distribution: '土耳其'
    },
    {
      language: '波兰语',
      distribution: '波兰'
    },
    {
      language: '瑞典语',
      distribution: '瑞典'
    },
    {
      language: '芬兰语',
      distribution: '芬兰'
    },
    {
      language: '挪威语',
      distribution: '挪威'
    },
    {
      language: '荷兰语',
      distribution: '芬兰、比利时'
    },
    {
      language: '俄语',
      distribution: '俄罗斯、哈萨克斯坦'
    },
    {
      language: '丹麦语',
      distribution: '丹麦'
    },
    {
      language: '冰岛语',
      distribution: '冰岛'
    }
  ],
  ban2: [
    {
      language: '希伯来语',
      distribution: '以色列'
    },
    {
      language: '阿拉伯语',
      distribution: '埃及、叙利亚、黎巴嫩、巴勒斯坦、约旦'
    },
    {
      language: '汉语',
      distribution: '各地区方言'
    },
    {
      language: '日语',
      distribution: '法国、加拿大、瑞士（少量）、比利时'
    },
    {
      language: '韩语',
      distribution: '韩国、吉林省'
    },
    {
      language: '印地语',
      distribution: '印度'
    },
    {
      language: '泰语',
      distribution: '泰国'
    },
    {
      language: '越南语',
      distribution: '越南'
    },
    {
      language: '马来语',
      distribution: '马来西亚'
    },
    {
      language: '印尼语',
      distribution: '印度尼西亚'
    },
    {
      language: '哈萨克语',
      distribution: '哈萨克斯坦、新疆自治区'
    },
    {
      language: '粤语',
      distribution: '广东省、深圳市、香港特别行政区'
    },
    {
      language: '闽南语',
      distribution: '福建省、台湾省'
    },
    {
      language: '维吾尔语',
      distribution: '新疆自治区'
    },
    {
      language: '藏语',
      distribution: '西藏自治区'
    },
    {
      language: '蒙古语',
      distribution: '蒙古国、内蒙古自治区、新疆自治区'
    },
    {
      language: '泰米尔语',
      distribution: '冰岛'
    }
  ],
  ban3: [
    {
      language: '斯瓦希里语',
      distribution: '坦桑尼亚、肯尼亚、乌干达、刚果民主共和国、赞比亚、卢旺达、索马里、莫桑比克'
    },
    {
      language: '英语',
      distribution: '南非、津巴布韦、尼日利亚、肯尼亚、埃塞俄比亚、乌干达'
    },
    {
      language: '法语',
      distribution: '尼日利亚、科特迪瓦、喀麦隆、摩洛哥、中非、塞内加尔、卢旺达、几内亚、尼日尔阿尔及利亚、突尼斯、马达加斯加'
    }
  ]
}
