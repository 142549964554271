export default {
  title: 'Fun Work App',
  download: 'Download',
  banner: 'Turn on your phone anytime, anywhere to easily complete tasks to make money. Hurry up and download the Functional Walker app to start making money!',
  feature1: 'Efficient',
  feature2: 'Convenient',
  feature3: 'Quick',
  feature4: 'Security',
  links: 'Download Links: ',
  howdoTitle: 'How to use FunWork APP for tasks',
  step1: 'Verified',
  step2: 'Receive the task',
  step2_1: 'Select the task in the list of recommended tasks',
  step2_2: 'Select the task in the list of all tasks',
  step3: 'View tasks to be submitted',
  step4: 'View audit progress',
  step5: 'Withdrawal',
  step6: 'Check in entrance'
}
