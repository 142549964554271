<template>
  <footer class="footer">
    <div class="footer-info">
      <div class="footer-info-l">
        <div class="logo">
          <img src="@/assets/image/logo-white.svg" alt="fanhanlogo">
          <div class="text">
            <div>泛函科技</div>
            <div class="en">FANHAN TECH</div>
          </div>
        </div>
        <div class="qr">
          <img src="@/assets/image/QR Code.jpg" alt="code">
          <div>{{$t('headfoot.qr')}}</div>
        </div>
      </div>
      <div class="footer-info-r">
        <!-- 服务项目 -->
        <ul>
          <li class="footer-info-title">{{$t('headfoot.servProjects')}}<i class="el-icon-plus"></i></li>
          <li><a class="link" href="/core">{{$t('headfoot.core')}}</a></li>
          <li><a class="link" href="/service">{{$t('headfoot.service')}}</a></li>
        </ul>
        <!-- 关于泛函 -->
        <ul>
          <li class="footer-info-title">{{$t('headfoot.about')}}<i class="el-icon-plus"></i></li>
          <li><a class="link" href="/about">{{$t('headfoot.business')}}</a></li>
          <li><a class="link" href="/news">{{$t('headfoot.news')}}</a></li>
          <li><a class="link" href="/resource">{{$t('headfoot.resource')}}</a></li>
        </ul>
        <!-- 相关产品 -->
        <ul>
          <li class="footer-info-title">{{$t('headfoot.projects')}}<i class="el-icon-plus"></i></li>
          <li><a class="link" href="/data-platform">{{$t('headfoot.colTagPlatform')}}</a></li>
          <li><a class="link" href="/app">{{$t('headfoot.app')}}</a></li>
        </ul>
        <!-- 定制数据 -->
        <ul>
          <li class="footer-info-title"><a class="link-title" href="/customize">{{$t('headfoot.customData')}}</a><i class="el-icon-plus"></i></li>
          <li class="none-sm"><a class="link" href="/customize">{{$t('headfoot.customData')}}</a></li>
        </ul>
        <!-- 联系我们 -->
        <ul class="contact">
          <li class="footer-info-title text-left">{{$t('headfoot.contact')}}<i class="el-icon-close"></i></li>
          <li><i class="el-icon-message icon"></i>fanhan@fanhantech.com</li>
          <li><i class="el-icon-phone-outline icon"></i>4008776680</li>
          <li><i class="el-icon-map-location icon"></i><span v-html="$t('headfoot.address')"></span></li>
        </ul>
      </div>
    </div>
    <div class="footer-copy">© 2016-2022 苏州泛函信息科技有限公司. 保留所有权 | <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color:#fff">苏ICP备2022026370号-1</a></div>
  </footer>
</template>

<script>
export default {
  name: 'thisfooter',
  data () {
    return {
      dev: false
    }
  },
  computed: {
    contactEn () {
      return this.$i18n.locale !== 'zh'
    }
  },
  methods: {
    runTo (router) {
      router = '/' + router
      const [rPathname, rHash] = router.split('#')
      const { pathname, hash } = window.location
      if (pathname + hash === router) {
        return
      }
      if (this.dev) {
        if (!rHash) {
          router = router + '.html'
        } else {
          router = router.replace('#', '.html#')
        }
      }
      window.location.href = rPathname === pathname ? '#' + rHash : router
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  color: #fff;
  background-image: linear-gradient(-90deg, #2D2A48, #000);
}
.footer-info {
  padding: 50px 50px 50px 10rem;
  display: flex;
  justify-content: space-between;
}
.footer-info-l {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 10.25rem;
  max-width: 10.25rem;
  margin-right: 2rem;
  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    img {
      width: 3.375rem;
    }
    .text {
      font-size: 1.3rem;
      .en {
        font-size: 0.8rem;
      }
    }
  }
  .qr {
    width: 100%;
    img {
      display: block;
      width: 80%;
      margin: 0 auto;
    }
    div {
      margin-top: 0.7rem;
      color: #AAAAAA;
      text-align: center;
    }
  }
}
.footer-info-r {
  color: #aaa;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  ul {
    margin-top: 2rem;
    padding: 0 1rem;
    max-width: 18.75rem;
  }
  .contact i {
    line-height: 2rem;
  }
}
.footer-info-title {
  color: #fff;
  font-size: 17px;
  margin-bottom: 2rem;
  text-align: center;
  i {
    display: none;
  }
}
a.link-title {
  color: #fff;
  text-decoration: none;
}
.footer-copy {
  padding: 1.5rem 10rem;
  font-size: 14px;
}
.link {
  color: #aaa;
  line-height: 2rem;
  &:hover {
    text-decoration: underline;
  }
}
.text-left {
  text-align: left;
}
.icon {
  margin-right: 1rem;
}
@media (max-width: 1300px) {
  .footer-info {
    padding: 50px 50px 50px 4rem;
  }
  .footer-copy {
    padding: 1.5rem 4rem;
  }
}
@media (max-width: 1200px) {
  .footer-info {
    padding: 20px;
    padding-left: 4rem;
  }
  .footer-info-l {
    display: none;
  }
  .footer-info-r {
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
@media (max-width: 768px) {
  .footer-info {
    padding: 30px 30px 0;
    flex-direction: column;
  }
  .footer-info-l {
    display: block;
    min-width: 100%;
    max-width: 100%;
    margin-right: 0;
    .qr {
      display: none;
    }
    .logo {
      justify-content: center;
      img {
        width: 40px;
        margin-right: 1rem;
      }
      .text {
        font-size: 25px;
        .en {
          display: none;
        }
      }
    }
  }
  .footer-info-r {
    flex-flow: column nowrap;
    ul {
      margin-top: 0;
      padding: 0 3px;
      max-width: 100%;
      li {
        margin: 5px 0;
      }
      &:last-child {
        border-bottom: 1px solid #6A767B;
      }
    }
  }
  .footer-info-title {
    text-align: left;
    font-size: 14px;
    margin-bottom: 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #6A767B;
    display: flex;
    justify-content: space-between;
    i {
      display: block;
    }
  }
  .contact .footer-info-title {
    border: none;
  }
  .none-sm {
    display: none;
  }
  .footer-copy {
    padding: 10px 30px;
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
