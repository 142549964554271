export default {
  name: 'Fanhan Tech',
  dataPlatform: 'Fanhan Platform',
  project: 'Services',
  product: 'Product',
  customize: 'Customized',
  howCustomize: 'How to customize the service',
  theirVoice: 'Their Voice',
  fhapp: 'Fun Work APP',
  data: 'Platform',
  core: 'Core Business',
  server: 'Services',
  advantage: 'Advantages',
  about: 'About Us',
  news: 'Latest News',
  team: 'Our Team',
  honor: 'Honor',
  partner: 'Partners',
  join: 'Join Us',
  contact: 'Contact Us',
  change: 'Switch Language',
  customer: 'Customer',
  cnnotate: 'Annotate',
  address: 'Building E, Suzhou National University Science and Technology Park of Nanotechnology, 388 Ruoshui Road, SuzhouIndustrial Park, Suzhou 215128, PRC.',
  sitemap: 'Sitemap',
  process: 'Service Process',
  links: 'Links'
}
