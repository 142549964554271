export default {
  platform: 'Functional Labeling Platform',
  info1: 'New channels for task release, cost controllable, to provide you with high-quality data services',
  info11: 'Controllable cost',
  info12: 'Self-check',
  info2: 'The new direction of online part-time job, no deposit required, easy withdrawal of one yuan, easy earning of pocket money',
  info21: 'No deposit required',
  info22: 'One dollar withdrawal',
  in: 'Fanhan Platform',
  how: 'How to proceed ',
  img: 'Picture Collection',
  voice: 'Voice Collection',
  text: 'Text collection',
  video: 'Video capture',
  step1: 'First step',
  step2: 'Second step',
  step3: 'Third step',
  all3: 'Complete the task and submit<br>to obtain the corresponding functional currency',
  img1: 'Get the mission<br>Please take pictures of pedestrians on the street',
  img2: 'Users take corresponding pictures',
  voice1: 'Read the sentence in the task aloud<br/>for example: "Hello, functional"',
  voice2: 'The user reads <br>"Hello, functional" to the recording device',
  text1: 'Simulation scenario writing a question and answer dialogue',
  text2: 'User writes dialogue and checks logic',
  video1: 'Acquire video clips based on task content',
  video2: 'User captures the corresponding video clip'
}
