export default {
  collect: 'Collect Task',
  mark: 'Annotation Task',
  image: 'image',
  wav: 'wav',
  text: 'text',
  video: 'video',
  img1: 'Cut',
  img2: 'Rectangle',
  img3: 'Polygon',
  img4: 'Line',
  img5: 'Key Points',
  img6: 'Sort',
  wav1: 'Cut',
  wav2: 'Transliterate',
  text1: 'Sort',
  text2: 'Transliterate',
  video1: 'Track',
  video2: 'Sort'
}
