export default {
  bannertitle: '全球领先的AI训练集数据<br>定制服务提供商',
  bannerc1: '泛函科技拥有36个国家、超过50种语言的稳定数据生产源和全球100000+活跃标注资源节点。',
  bannerc2: '覆盖国家',
  bannerc3: '覆盖语言',
  scenesContent: '根据客户特定的场景需求和个性化参数要求，泛函科技可以为客户提供包括数据规划、数据采集、数据预处理、数据转写标注、测试评估等一系列服务在内的“一站式数据服务”，帮助客户在人工智能领域抢先取得技术进步，赢得市场先机！',
  scenesT1: '人脸识别',
  scenesD1: '精准定位人脸，获得眼、口、鼻等150个关键点位置，用于分析性别、年龄、表情等多种人脸属性',
  scenesT2: '自然语言理解',
  scenesD2: '模拟人的语言交际过程，使计算机能理解和运用人类社会的自然语言，实现人机之间的自然语言通信',
  scenesT3: '机器人',
  scenesD3: '智能机器人能够理解人类语言，用人类语言同操作者对话，它能分析出现的情况，能调整自己的动作以达到操作者所提出的全部要求',
  scenesT4: '无人驾驶',
  scenesD4: '利用车载传感器感知周围环境，根据所获得的道路、车辆位置和障碍物信息，控制车辆的转向和速度，从而使车辆能够安全、可靠地在道路上行驶',
  scenesT5: '智能搜索',
  scenesD5: '提供快速检索、相关度排序等功能，以及用户兴趣自动识别、内容的语义理解、智能信息化过滤和推送等功能',
  scenesT6: '机器翻译',
  scenesD6: '机器翻译是利用计算机将一种自然语言(源语言)转换为另一种自然语言(目标语言)的过程',
  scenesT7: '语言合成',
  scenesD7: '语音合成是利用电子计算机和一些专门装置模拟人，制造语音的技术',
  scenesT8: '图像识别',
  scenesD8: '利用计算机对图像进行处理、分析和理解，以识别各种不同模式的目标和对象',
  scenesT9: '语音识别',
  solutionsH1: '采集标注平台',
  solutionsD1: '100000+标注员线上采标，覆盖了36个国家50多种语言，囊括了全数据类型；随时随地自主发布项目，第一时间知晓项目最新进展',
  solutionsH2: '泛函沃客APP',
  solutionsD2: '支持手机采集图片、语言等类型数据，更便捷、更流畅的使用体验',
  solutionsH3: '一站式数据服务',
  solutionsD3: '根据客户需求，定制训练集数据解决方案，提供采集-拆分-标注等一站式服务',
  more: '了解更多'
}
