export default {
  bannertitle: 'Global Leading<br>AI Training Data Set Provider',
  bannerc1: 'At present, business in Fanhan Tech has covered Asia, America, North Africa, Europe and other countriesand regions.',
  bannerc2: 'Countries',
  bannerc3: 'Languages (Accents)',
  scenesContent: 'According to the specific requirements, Fanhan Tech could provide customers with a series of "one-stop service", such as data acquisition, data preprocessing, data transfer marking, test and evaluation, etc.',
  scenesT1: 'Face Recognition',
  scenesD1: 'Accurately locate the face, obtain 150 key points such as eyes, mouth, and nose, which are used to analyze various face attributes such as gender, age, and expression',
  scenesT2: 'Natural Language Understanding',
  scenesD2: 'Simulate the process of human language communication, so that computers can understand and use the natural language of human society, and realize natural language communication between humans and computers',
  scenesT3: 'Robot',
  scenesD3: 'The intelligent robot can understand human language and use human language to talk to the operator. It can analyze the situation and adjust its actions to meet all the requirements of the operator.',
  scenesT4: 'Unmanned Driving',
  scenesD4: 'Use on-board sensors to perceive the surrounding environment, and control the steering and speed of the vehicle according to the obtained road, vehicle location and obstacle information, so that the vehicle can safely and reliably drive on the road',
  scenesT5: 'Intelligent Search',
  scenesD5: 'Provide functions such as fast retrieval, relevance ranking, and automatic identification of user interests, semantic understanding of content, intelligent information filtering and push functions',
  scenesT6: 'Machine Translation',
  scenesD6: 'Machine translation is the process of using a computer to convert one natural language (source language) into another natural language (target language)',
  scenesT7: 'Speech Synthesis',
  scenesD7: 'Speech synthesis is a technology that uses electronic computers and some special devices to simulate humans and produce speech',
  scenesT8: 'Image Recognition',
  scenesD8: 'Use computers to process, analyze and understand images to identify targets and objects in various patterns',
  scenesT9: 'Speech Recognition',
  solutionsH1: 'Collection and annotation platform',
  solutionsD1: '100,000+ annotators adopt online bidding, covering more than 50 languages in 36 countries, including all data types; independent release of projects anytime, anywhere, the first time to know the latest progress of the project',
  solutionsH2: 'Fun Work APP',
  solutionsD2: 'Support mobile phone to collect pictures, language and other types of data, more convenient and smoother experience',
  solutionsH3: 'One-stop data service',
  solutionsD3: 'Customize training set data solutions according to customer needs, and provide one-stop services such as collection-split-labeling',
  more: 'More'
}
