<template>
  <div class="back-top-wrap d-none" ref="backTop" @click="scrollTop">
    <i class="el-icon-caret-top"></i>
  </div>
</template>

<script>
export default {
  name: 'BackTop',
  components: {
  },
  props: {
  },
  data () {
    return {
      show: false
    }
  },
  mounted: function () {
    window.addEventListener('scroll', () => {
      const scrollTop = document.documentElement.scrollTop
      if (scrollTop > 200) {
        this.$refs.backTop.classList.remove('d-none')
      } else {
        this.$refs.backTop.classList.add('d-none')
      }
    }, true)
  },
  methods: {
    scrollTop () {
      let l = document.documentElement.scrollTop
      const t = 10
      const s = l / 600 * t
      const st = setInterval(function () {
        l -= s
        if (l <= 0) {
          l = 0
          clearInterval(st)
        }
        window.scrollTo(0, l)
      }, t)
    }
  }
}
</script>

<style lang="less" scoped>
.back-top-wrap {
  position: fixed;
  width: 40px;
  height: 40px;
  right: 30px;
  bottom: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f6fc;
  box-shadow: 0 0 6px rgba(0,0,0,.12);
  color: #0092ee;
  font-size: 20px;
  cursor: pointer;
  z-index: 9;
  &:hover {
    color: #fff;
    background-image: linear-gradient(#0092ee, #0065e4);
  }
}
@media (max-width: 768px) {
  .back-top-wrap {
    right: 10px;
    bottom: 10px;
  }
}
</style>
