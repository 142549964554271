













































































import Vue from 'vue'
import Cookies from 'js-cookie'
const thisheader = Vue.extend({
  props: {
    bcgBlack: Boolean
  },
  data () {
    return {
      open: false,
      dev: false,
      lang: this.$i18n.locale === 'zh',
      scroll: false
    }
  },
  mounted () {
    window.onscroll = () => {
      const scrollTop = document.documentElement.scrollTop
      this.scroll = scrollTop > 200
    }
  },
  methods: {
    runTo (router: string) {
      router = '/' + router
      const [rPathname, rHash] = router.split('#')
      const { pathname, hash } = window.location
      if (pathname + hash === router) {
        return
      }
      if (this.dev) {
        if (!rHash) {
          router = router + '.html'
        } else {
          router = router.replace('#', '.html#')
        }
      }
      window.location.href = rPathname === pathname ? '#' + rHash : router
    },
    changeLang () {
      this.lang = !this.lang
      const curLang = this.lang ? 'zh' : 'en'
      this.$i18n.locale = curLang
      Cookies.set('lang', curLang, { domain: 'fanhantech.com' })
      sessionStorage.setItem('lang', curLang)
    }
  }
})
export default thisheader
