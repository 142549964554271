export default {
  ban1: '数据采集',
  ban2: '数据预处理',
  ban3: '数据标注',
  ban4: '成品训练集数据',
  details11: '1）丰富的全球采集节点资源，深入当地直接采集；',
  details12: '2）定制化采集软件，满足各种特定场景的数据采集需求；',
  details13: '3）专业的项目管理团队，在项目管理、质量控制和成本优化等方面经验丰富，能实现高效率，高性价比，高品质数据的交付。',
  details1: [
    '1）丰富的全球采集节点资源，深入当地直接采集；',
    '2）定制化采集软件，满足各种特定场景的数据采集需求；',
    '3）专业的项目管理团队，在项目管理、质量控制和成本优化等方面经验丰富，能实现高效率，高性价比，高品质数据的交付。'
  ],
  details2: [
    '1）高效的数据拆分模型，大大缩短项目周期；',
    '2）自主研发的语音切割模型，高效切割各类复杂场景的语音数据；',
    '3）精准定位数据诉求，有效提升数据采标效率，安全高效地解决模型算法的数据来源问题。'
  ],
  details3: [
    '1）分布全球的100000+经验丰富标注员，拥有稳定数据标注生产源；',
    '2）自主研发线上标注平台，支持多类型多维度的数据标注，可准确、高效、安全地完成各类型数据标注任务；',
    '3）提供精准质检流程，数据准确率大于98%，帮助客户完成算法模型训练，助力人工智能行业发展。'
  ],
  details4: [
    '1）涵盖语音识别、图像识别、语义理解等多个领域的高质量成品训练集数据，为客户提供更多选择；',
    '2）定制化的成品训练集服务，更贴合客户的需求；',
    '3）帮助客户有效缩短研发周期，高效完成人工智能训练任务，同时节省训练成本，减少人力财力输出。'
  ],
  advantagesT1: '数字版权保障',
  advantagesD1: '严格把控数据流向，保障客户的数据私有性权益，杜绝二次出售，数据版权对您所有。',
  advantagesT2: '一站式服务保障',
  advantagesD2: '快速梳理数据需求，提供精准的定制化服务，构成了全流程、一站式的AI数据服务体系，针对客户痛点优化项目方案，助力产业智能化升级。',
  advantagesT3: '数据质量保障',
  advantagesD3: '交付数据准确率大于98％，严格的数据安全保护措施，先进的质检流程，标审分离，项目支持稳定可持续。',
  advantagesT4: '公司质量保障',
  advantagesD4: '泛函科技已通过ISO9001质量管理体系认证，国际安全质量管理体系认可，全程保证您的数据安全。',
  advantagesT5: '时间周期保障',
  advantagesD5: '全流程化管理模式和专业的质检系统，分布全球经验丰富的标注员和稳定的标注平台，大大提高采标效率，缩短项目周期。'
}
