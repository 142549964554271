export default {
  collect: '采集任务',
  mark: '标注任务',
  image: '图片任务',
  wav: '语音任务',
  text: '文本任务',
  video: '视频任务',
  img1: '语义分割',
  img2: '矩形',
  img3: '多边形',
  img4: '线段',
  img5: '关键点',
  img6: '分类',
  wav1: '语音切割',
  wav2: '语音转写',
  text1: '文本分类',
  text2: '文本转写',
  video1: '视频跟踪',
  video2: '视频分类'
}
