export default {
  name: '泛函科技',
  dataPlatform: '泛函平台',
  home: '首页',
  servProjects: '服务项目',
  about: '关于泛函',
  projects: '相关产品',
  core: '核心业务与优势',
  service: '一站式数据服务',
  business: '企业概况',
  news: '最新动态',
  resource: '资源概况',
  colTagPlatform: '采集标注平台',
  app: '泛函沃客APP',
  customData: '定制数据',
  contact: '联系我们',
  customer: '客户业务咨询',
  cnnotate: '标注员服务咨询',
  address: '苏州纳米技术国家大学科技园E栋603',
  qr: '扫码关注微信公众号'
}
