// 语言模块自动注册
const requireModule = require.context('./en', true, /\.js$/)
const modules = {}

requireModule.keys().forEach(fileName => {
  const moduleName = fileName.replace(/(\.\/|\.js)/g, '')
  modules[moduleName] = {
    ...requireModule(fileName).default
  }
})

const en = { ...modules }
export default en
