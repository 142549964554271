<template>
  <div class="section-title">
    <div class="zh" :class="{'zh-2': stynum===2}">{{zh}}</div>
    <div class="en" :class="{'en-2': stynum===2}">{{en}}</div>
    <div class="line" :class="{'line-2': stynum===2}"></div>
  </div>
</template>

<script>
export default {
  name: 'section-title',
  props: {
    zh: {
      type: String,
      required: true
    },
    en: {
      type: String,
      required: true
    },
    stynum: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="less" scoped>
.section-title {
  text-align: center;
  width: 100%;
  margin-bottom: 3rem;
}
.zh {
  font-size: 2.25rem;
  font-weight: bold;
  color: #464545;
}
.zh-2 {
  color: #fff;
}
.en {
  font-size: 1.4375rem;
  color: #6F9BE7;
}
.en-2 {
  color: #AAC4F5;
}
.line {
  margin: 0 auto;
  width: 9.375rem;
  height: .5rem;
  border-bottom: 3px solid #464545;
}
.line-2 {
  width: 11.5625rem;
  border-bottom: 2px solid #fff;
}
@media (max-width: 768px) {
  .zh {
    font-size: 20px;
  }
  .en {
    font-size: 14px;
  }
  .line {
    width: 75px;
  }
}
</style>
