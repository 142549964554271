export default {
  name: '泛函科技',
  fhdata: '泛函平台',
  project: '服务项目',
  product: '产品',
  customize: '定制服务',
  howCustomize: '如何定制服务',
  theirVoice: '他们的声音',
  fhapp: '泛函 APP',
  data: '标注平台',
  core: '核心业务',
  server: '数据服务',
  advantage: '竞争优势',
  about: '关于泛函',
  news: '最新动态',
  team: '泛函团队',
  honor: '获得荣誉',
  partner: '合作伙伴',
  join: '加入我们',
  contact: '联系我们',
  change: '切换语言',
  customer: '客户业务咨询',
  cnnotate: '标注员服务咨询',
  address: '苏州纳米技术国家大学科技园E栋603',
  sitemap: '网站地图',
  process: '服务流程',
  links: '友情链接'
}
