export default {
  banT1: 'Europe and America',
  banT2: 'Asia Pacific and Middle East',
  banT3: 'Africa',
  language: 'Language',
  distributed: 'Distributed',
  partPar: 'Fanhan Tech provides data services for Google, Facebook, Baidu, Samsung and other world-renowned companies throughout the year, and has established a long-term and stable cooperative relationship to work together for the future development of AI.',
  joinPar: 'We hope to promote the construction of the artificial intelligence ecological chain with high-quality data and services and industry partners. You are welcome to join us to make truly beneficial changes for the future development of society and create the future of artificial intelligence.',
  joinApply: 'Join Us',
  details: 'Details',
  ban1: [
    {
      language: 'English',
      distribution: 'United States, United Kingdom, New Zealand, Australia, Ireland, India, Singapore, Canada, Mexico'
    },
    {
      language: 'French',
      distribution: 'France, Canada, Switzerland (a small amount), Belgium'
    },
    {
      language: 'German',
      distribution: 'Germany, Austria, Switzerland, Belgium (small amount)'
    },
    {
      language: 'Italian',
      distribution: 'Italy'
    },
    {
      language: 'Spanish',
      distribution: 'Spain, Mexico, Argentina'
    },
    {
      language: 'Portuguese',
      distribution: 'Portugal, Brazil'
    },
    {
      language: 'Turkish',
      distribution: 'Turkey'
    },
    {
      language: 'Polish',
      distribution: 'Poland'
    },
    {
      language: 'Swedish',
      distribution: 'Sweden'
    },
    {
      language: 'Finnish',
      distribution: 'Finland'
    },
    {
      language: 'Norwegian',
      distribution: 'Norway'
    },
    {
      language: 'Dutch',
      distribution: 'Finland, Belgium'
    },
    {
      language: 'Russian',
      distribution: 'Russia, Kazakhstan'
    },
    {
      language: 'Danish',
      distribution: 'Denmark'
    },
    {
      language: 'Icelandic',
      distribution: 'Iceland'
    }
  ],
  ban2: [
    {
      language: 'Hebrew',
      distribution: 'Israel'
    },
    {
      language: 'Arabic',
      distribution: 'Egypt, Syria, Lebanon, Palestine, Jordan'
    },
    {
      language: 'Chinese',
      distribution: 'Dialects of various regions'
    },
    {
      language: 'Japanese',
      distribution: 'France, Canada, Switzerland (a small amount), Belgium'
    },
    {
      language: 'Korean',
      distribution: 'South Korea, Jilin Province'
    },
    {
      language: 'Hindi',
      distribution: 'India'
    },
    {
      language: 'Thai',
      distribution: 'Thailand'
    },
    {
      language: 'Vietnamese',
      distribution: 'Vietnam'
    },
    {
      language: 'Malay',
      distribution: 'Malaysia'
    },
    {
      language: 'Indonesian',
      distribution: 'Indonesia'
    },
    {
      language: 'Kazakh',
      distribution: 'Kazakhstan, Xinjiang Autonomous Region'
    },
    {
      language: 'Cantonese',
      distribution: 'Guangdong Province, Shenzhen, Hong Kong Special Administrative Region'
    },
    {
      language: 'Minnan Language',
      distribution: 'Fujian Province, Taiwan Province'
    },
    {
      language: 'Uyghur',
      distribution: 'Xinjiang Autonomous Region'
    },
    {
      language: 'Tibetan language',
      distribution: 'Tibet Autonomous Region'
    },
    {
      language: 'Mongolian',
      distribution: 'Mongolia, Inner Mongolia Autonomous Region, Xinjiang Autonomous Region'
    },
    {
      language: 'Tamil',
      distribution: 'Iceland'
    }
  ],
  ban3: [
    {
      language: 'Swahili',
      distribution: 'Tanzania, Kenya, Uganda, Democratic Republic of Congo, Zambia, Rwanda, Somalia, Mozambique'
    },
    {
      language: 'English',
      distribution: 'South Africa, Zimbabwe, Nigeria, Kenya, Ethiopia, Uganda'
    },
    {
      language: 'French',
      distribution: 'Nigeria, Côte d’Ivoire, Cameroon, Morocco, Central Africa, Senegal, Rwanda, Guinea, Niger, Algeria, Tunisia, Madagascar'
    }
  ]
}
