export default {
  name: 'Fanhan Tech',
  dataPlatform: 'Fanhan Platform',
  home: 'Home',
  servProjects: 'Service',
  about: 'About',
  projects: 'Projects',
  core: 'Core Business and Advantages',
  service: 'One-stop Data Service',
  business: 'Company Profile',
  news: 'Latest News',
  resource: 'Resource Overview',
  colTagPlatform: 'Collection and Tagging Platform',
  app: 'Fun Work APP',
  customData: 'Customize',
  contact: 'Contact Us',
  customer: 'Customer',
  cnnotate: 'Annotate',
  address: 'Building E, Suzhou National University Science and Technology Park of Nanotechnology, 388 Ruoshui Road, SuzhouIndustrial Park, Suzhou 215128, PRC.',
  qr: 'Scan QR code to follow WeChat official account'
}
