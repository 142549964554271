import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import enLocale from './en'
import zhLocale from './zh'

Vue.use(VueI18n)

const navigatorLanguage = navigator.language.substr(0, 2)
const navigatorLanguageFormat = navigatorLanguage === 'zh' ? 'zh' : 'en'
const sessionLanguage = sessionStorage.getItem('lang') === 'zh' ? 'zh' : 'en'
const cookieLanguage = Cookies.get('lang')

export const lang = cookieLanguage || sessionLanguage || navigatorLanguageFormat || 'en'

console.log('lang', lang)

const messages = {
  en: {
    ...enLocale
  },
  zh: {
    ...zhLocale
  }
}

const i18n = new VueI18n({
  locale: lang,
  messages
})

// export const language = (key) => {
//   var keys = (i18n.locale + '.' + key).split('.')
//   var tmp = messages
//   // console.log(key, lang, keys, tmp)
//   for (const e of keys) {
//     if (!tmp[e]) {
//       return key
//     }
//     tmp = tmp[e]
//   }
//   return tmp
// }

// 根据语言改变窗口的title
// if (lang === 'en') {
//   document.title = 'Fanhan Tech'
// } else {
//   document.title = '泛函科技'
// }

export default i18n
