export default {
  ban1: 'Data Collection',
  ban2: 'Data Pre-processing',
  ban3: 'Data Annotations',
  ban4: 'Finished Training Set Data',
  details11: '1) Abundant global collection node resources, in-depth local collection directly;',
  details12: '2) Customized acquisition software to meet the data acquisition needs of various specific scenarios;',
  details13: '3) A professional project management team with rich experience in project management, quality control and cost optimization can achieve high efficiency, high cost performance, and high quality data delivery. ',
  details1: [
    '1) Abundant global collection node resources, in-depth local collection directly;',
    '2) Customized acquisition software to meet the data acquisition needs of various specific scenarios;',
    '3) A professional project management team with rich experience in project management, quality control and cost optimization can achieve high efficiency, high cost performance, and high quality data delivery. '
  ],
  details2: [
    '1) An efficient data splitting model greatly shortens the project cycle;',
    '2) The self-developed voice cutting model can efficiently cut voice data in various complex scenarios;',
    '3) Accurately locate data requirements, effectively improve the efficiency of data acquisition, and safely and efficiently solve the problem of data sources for model algorithms. '
  ],
  details3: [
    '1) 100,000+ experienced annotators all over the world have stable data annotation production sources;',
    '2) Independently researched and developed online labeling platform, which supports multiple types and multiple dimensions of data labeling, and can accurately, efficiently and safely complete various types of data labeling tasks;',
    '3) Provide accurate quality inspection process, data accuracy rate is greater than 98%, help customers complete algorithm model training, and help the development of artificial intelligence industry. '
  ],
  details4: [
    '1) High-quality finished training set data covering multiple fields such as speech recognition, image recognition, semantic understanding, etc., to provide customers with more choices;',
    '2) Customized finished product training set service, more suitable for customer needs;',
    '3) Help customers effectively shorten the research and development cycle, complete artificial intelligence training tasks efficiently, save training costs, and reduce human and financial output. '
  ],
  advantagesT1: 'Digital copyright protection',
  advantagesD1: 'Strictly control the flow of data, protect the privacy rights of customers, and prevent secondary sales. The copyright of the data belongs to you. ',
  advantagesT2: 'One-stop service guarantee',
  advantagesD2: 'Quickly sort out data needs, provide accurate customized services, constitute a full-process, one-stop AI data service system, optimize project plans for customer pain points, and help the industry to intelligently upgrade. ',
  advantagesT3: 'Data quality assurance',
  advantagesD3: 'Delivery data accuracy rate is greater than 98%, strict data security protection measures, advanced quality inspection process, standard review separation, project support is stable and sustainable. ',
  advantagesT4: 'Company Quality Assurance',
  advantagesD4: 'Fanhan Technology has passed ISO9001 quality management system certification and international security quality management system certification, ensuring your data security throughout the process. ',
  advantagesT5: 'time cycle guarantee',
  advantagesD5: 'Full-process management model and professional quality inspection system, distributed global experienced labelers and stable labeling platform, greatly improve the efficiency of standard adoption and shorten the project cycle. '
}
