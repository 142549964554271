export default {
  ideaDetail: '“我们做的不仅是数据，更是改变”，一直是泛函科技的经营理念，我们希望以高质量的数据与服务和业界合作伙伴一起推动建设人工智能生态链，为人类的未来发展做出真正有益的改变。',
  idea1: '第一时间响应客户的需求，提供高效的沟通',
  idea2: '为顾客提供市场上高质量的数据、平台以及服务',
  idea3: '根据客户的需求，提供灵活性的数据与定制化服务',
  idea4: '提供真实的数据，建立真诚的合作关系',
  teamN1: '年轻团队',
  teamN2: '技术开发',
  teamN3: '项目管理',
  team1D1: '泛函科技隶属于苏州泛函信息科技有限公司，核心团队是一群',
  team1D2: '年轻活力',
  team1D3: '的青年， 包括技术先进的',
  team1D4: '研发团队',
  team1D5: '和经验丰富的',
  team1D6: '项目管理团队',
  team1D7: '，这些来自',
  team1D8: '语音、图片、视频',
  team1D9: '等不同领域的优秀人才，致力于推动人工智能的未来发展。',
  team2D1: '目前，泛函科技拥有',
  team2D2: '技术开发人员15名（其中博士2名，硕士13名）',
  team2D3: '，负责',
  team2D4: '语音、图片、文字、视频',
  team2D5: '等数据采集和数据处理的辅助工具开发，研发任务难度评判、数据定价、数据库质量评价等算法，可以为用户提供高效的技术支持服务。',
  team3D1: '泛函科技还拥有专业的项目管理团队，在项目管理、质量控制和成本优化等方面经验丰富，能满足客户大批量的数据采集、处理和标注的个性化需求，能实现',
  team3D2: '高效率，高性价比，高品质数据',
  team3D3: '的交付。',
  honorN1: '资质认证',
  honorN2: '软著',
  honorN3: '质量认证',
  honorN4: '其他荣誉',
  mapf1: '苏州',
  mapf2: '苏州纳米技术国家大学科技园E栋603',
  maps1: '北京',
  maps2: '北京市海淀区中关村创业大街2号楼3层',
  mapt1: '多伦多',
  news: '最新动态',
  news1: '泛函科技荣获“中国创翼”创业创新大赛创业组三等奖',
  news2: '泛函科技入选上市苗圃工程拟认定名单',
  news3: '泛函科技荣获2018苏州纳米技术国家大学科技园“合作之星”称号',
  news4: '泛函科技荣获第三届苏南股权路演年度“创新之星”称号',
  news5: '恭喜泛函科技 荣获创业创新大赛二等奖！',
  joinTxt: '我们希望以高质量的数据与服务和业界合作伙伴一起推动建设人工智能生态链，欢迎你加入我们，为社会未来的发展做出真正有益的改变',
  joinBtn: '申请加入',
  honor: '获得荣誉',
  partner: '合作伙伴',
  join: '加入我们'
}
